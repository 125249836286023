import React, {useState, useRef, useEffect} from "react";
import initialConfig from '../config/config.js';
import language from '../config/lang.js';
import './legal.css';

function Legal({lang, changeStatus, userLegal}) {
    
    const [userLegalData, setUserLegalData] = useState({aceptotodo: false, aceptoterminos: false, aceptoComunicacionPatrocinador: false, aceptoComunicacionAtm: false});
    const [view, setView] = useState("");
    const [required, setRequired] = useState(false);

    const reset = () => {
        setUserLegalData({aceptotodo: false, aceptoterminos: false, aceptoComunicacionPatrocinador: false, aceptoComunicacionAtm: false});
    }

    const aceptoTodo = () => {
        let legaldata = {...userLegalData};
        if (!legaldata.aceptotodo){
            legaldata.aceptotodo = true;
            legaldata.aceptoterminos = true;
            legaldata.aceptoComunicacionPatrocinador = true;
            legaldata.aceptoComunicacionAtm = true;
        }else{
            legaldata.aceptotodo = false;
            legaldata.aceptoterminos = false;
            legaldata.aceptoComunicacionPatrocinador = false;
            legaldata.aceptoComunicacionAtm = false;
        }
        setUserLegalData(legaldata);
    }

    const aceptoterminos = () => {
        let legaldata = {...userLegalData};
        if (!legaldata.aceptoterminos){
            legaldata.aceptoterminos = true;
            setRequired(false);
        }else{
            legaldata.aceptoterminos = false;
            legaldata.aceptotodo = false;
        }
        if (legaldata.aceptoterminos && legaldata.aceptoComunicacionAtm && legaldata.aceptoComunicacionPatrocinador) {
            legaldata.aceptotodo = true;
        }
        setUserLegalData(legaldata);
    }

    const aceptoComunicacionPatrocinador = () => {
        let legaldata = {...userLegalData};
        if (!legaldata.aceptoComunicacionPatrocinador){
            legaldata.aceptoComunicacionPatrocinador = true;
        }else{
            legaldata.aceptoComunicacionPatrocinador = false;
            legaldata.aceptotodo = false;
        }
        if (legaldata.aceptoterminos && legaldata.aceptoComunicacionAtm && legaldata.aceptoComunicacionPatrocinador) {
            legaldata.aceptotodo = true;
        }
        setUserLegalData(legaldata);
    }

    const aceptoComunicacionAtm = () => {
        let legaldata = {...userLegalData};
        if (!legaldata.aceptoComunicacionAtm){
            legaldata.aceptoComunicacionAtm = true;
        }else{
            legaldata.aceptoComunicacionAtm = false;
            legaldata.aceptotodo = false;
        }
        if (legaldata.aceptoterminos && legaldata.aceptoComunicacionAtm && legaldata.aceptoComunicacionPatrocinador) {
            legaldata.aceptotodo = true;
        }
        setUserLegalData(legaldata);
    }    

    return (
    <div className="legal" style={{backgroundColor: initialConfig.secondaryColor}}>   

        <h1 style={{color: initialConfig.mainColor}} >{language[lang].tit_data}</h1>       
        
        <form className="formulario">
            <div className="formgroup">            
                <label className="container" style={{color: initialConfig.mainColor}}>{language[lang].aceptotodo}
                    <input type="checkbox" checked={userLegalData.aceptotodo?"checked":""}/>
                    <span onClick={()=>{
                        console.log("Aceptar todo")
                        aceptoTodo();
                    }} className="checkmark" style={{border: "2px solid "+initialConfig.mainColor}}></span>
                </label>                
            </div>

            <div className="formgroup2">    
                <label className="container" style={{color: initialConfig.mainColor}}>{language[lang].aceptoterminos1} <a onClick={()=>setView("aceptoterminos")}>{language[lang].aceptoterminos2}</a> {language[lang].aceptoterminos3}
                    <input type="checkbox" checked={userLegalData.aceptoterminos?"checked":""}/>
                    <span onClick={()=>{
                        aceptoterminos();
                    }} className="checkmark" style={{backgroundColor: required ? "rgba(255,0,0,0.3)":"", border: "2px solid "+initialConfig.mainColor}}></span>
                </label>   
                <label className="container" style={{color: initialConfig.mainColor}}>{language[lang].aceptoComunicacionPatrocinador1} <a onClick={()=>setView("aceptoComunicacionPatrocinador")}>{language[lang].aceptoComunicacionPatrocinador2}</a> {language[lang].aceptoComunicacionPatrocinador3}
                    <input type="checkbox" checked={userLegalData.aceptoComunicacionPatrocinador?"checked":""}/>
                    <span onClick={()=>{
                        aceptoComunicacionPatrocinador();
                    }} className="checkmark" style={{border: "2px solid "+initialConfig.mainColor}}></span>
                </label>   
                <label className="container" style={{color: initialConfig.mainColor}}>{language[lang].aceptoComunicacionAtm1} <a onClick={()=>setView("aceptoComunicacionAtm")}>{language[lang].aceptoComunicacionAtm2}</a> {language[lang].aceptoComunicacionAtm3}
                    <input type="checkbox" checked={userLegalData.aceptoComunicacionAtm?"checked":""}/>
                    <span onClick={()=>{
                        aceptoComunicacionAtm();
                    }} className="checkmark" style={{border: "2px solid "+initialConfig.mainColor}}></span>
                </label>                   
            </div>
        </form>
        <div className="continuar" style={{backgroundColor: initialConfig.mainColor}} onClick={()=>{
                if (!userLegalData.aceptoterminos){
                    setRequired(true);
                }else{
                    userLegal(userLegalData);
                    reset();
                    changeStatus("players");
                }
        }}>{language[lang].continue}</div>

        {view!=="" && 
            <div className="legalWindow">                
                <div className="content"  dangerouslySetInnerHTML={{__html: language[lang]["legal_"+view]}}></div>
                <div className="close" onClick={()=>setView("")} style={{backgroundColor: initialConfig.mainColor}}>CLOSE</div>
            </div>
        }

    </div>
    );

}  

export default Legal;