import React, {useState, useRef, useEffect, useCallback} from "react";
import anime from "animejs/lib/anime.es.js"

function Transition({color, state}) {
    
    const transitionUp = useRef(null);
    const transitionDown = useRef(null);
    const animationDuration = 1000;

    useEffect(() => {
      if (state=="close") {
        anime({
          targets: transitionUp.current,
          easing: 'easeOutQuad',
          duration: animationDuration,
          top: 2600,
        })
        anime({
          targets: transitionDown.current,
          easing: 'easeOutQuad',
          duration: animationDuration,
          top: -1200,
        })        
      }else if (state=="open") {        
        anime({
          targets: transitionUp.current,
          easing: 'easeInQuad',
          duration: animationDuration,
          top: -1000,
        })
        anime({
          targets: transitionDown.current,
          easing: 'easeInQuad',
          duration: animationDuration,
          top: 3840,
        })
      }
    }, [state]);

    return(
    <>      
      <svg ref={transitionUp} className="transitionUp" style={{animationDuration:animationDuration+"s"}} height="1500" width="1500">
        <path d="M0 0L3776 3776L3776 0L0 0Z" id="Vector" fill={color} fillRule="evenodd" stroke="none" />
	    </svg>      
      <svg ref={transitionDown} className="transitionDown" style={{animationDuration:animationDuration+"s"}} height="1500" width="1500">
        <path d="M0 0L3776 3776L3776 0L0 0Z" id="Vector" fill={color} fillRule="evenodd" stroke="none" />
      </svg>
    </>
    );
}  

export default Transition;