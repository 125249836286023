import React from 'react'
import './Header.css'
import initialConfig from '../../config/config.js';

const Header = () => <>
    <div className="header"> 
        <img className="logo" onClick={()=>window.location.reload()} src={initialConfig.data_url + initialConfig.logo_app}/>
    </div>        
</>

export default Header