import React, {useState, useRef, useEffect} from "react";
import initialConfig from '../config/config.js';
import language from '../config/lang.js';
import './photo.css';
import FadeUp from '../assets/fade_up.png';

function Photo({lang, name, screenshot, changeStatus}) {

    const photoRef = useRef();

    useEffect(
      () => {
        let timer1 = setTimeout(() => changeStatus("send"), initialConfig.timeOut_seconds * 1000);
        return () => {
          clearTimeout(timer1);
        };
    },[]);

    return (
      <div className="photo"  style={{backgroundColor: initialConfig.secondaryColor}}>         
        <h1 style={{color: initialConfig.mainColor}} dangerouslySetInnerHTML={{__html: language[lang].tit_photo}} ></h1>       
        <div className='photoHolder' onClick={()=>changeStatus("welcome")} ref={photoRef} style={{"backgroundImage": "url("+screenshot+")"}}></div>
        <div className="buttons">
          <div className="repeat" style={{color: initialConfig.mainColor, border: "4px solid " + initialConfig.mainColor}} onClick={()=>{
                    changeStatus("repeat")
            }}>{language[lang].repeat}</div>
          <div className="continuar" style={{backgroundColor: initialConfig.mainColor}} onClick={()=>{
                    changeStatus("send")
            }}>{language[lang].continue}</div>
        </div>
      </div>
    );

}  

export default Photo;