import React, {useState, useRef, useEffect} from "react";
import _ from 'lodash';
import CrossfadeImage from "react-crossfade-image";
import Webcam from "react-webcam";
import timer_1 from '../assets/timer_1.png';
import timer_2 from '../assets/timer_2.png';
import timer_3 from '../assets/timer_3.png';
import timer_4 from '../assets/timer_4.png';
import timer_5 from '../assets/timer_5.png';
import FadeUp from '../assets/fade_up.png';
import Position from '../assets/position.png';
import './camera.css';
import initialConfig from '../config/config.js';
import language from '../config/lang.js';

function Camera({lang, status, changeStatus, setScreenshot, userPlayer, setUserBlob}) {
    
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);
    const fadeOverlay = useRef(null);
    const robotOverlay = useRef(null);
    const [cameraStatus, setCameraStatus] = useState("ready")
    const [timer, setTimer] = useState(timer_5)
    const [imageSrc, setImageSrc] = useState();
    const time = 1500;
    const x = 0;
    const y = 0;
    const image = new Image();
    const logo = new Image();
    const pose = new Image();            
    let photo = undefined;
    let step = 1;

    const videoConstraints = {
      width: 1920,
      height: 1080,
      facingMode: "user"
    };

    const drawImage = (ctx, img, x, y, angle = 0, scale = 1) =>{
      x = 540;
      y = 540;
      ctx.save();
      ctx.translate(x,y);
      ctx.rotate(angle*Math.PI/180);
      ctx.translate(-x, -y);
      ctx.drawImage(img, 0, 0, img.width * scale, img.height * scale);
      ctx.restore();
    }

    const nextStep = () => {
      step ++;
      console.log(step)
      switch (step) {
        case 1:
        break;
        case 2:
          setCameraStatus("robot");
          setTimeout(()=>{
            robotOverlay.current.style.opacity = 1;
          },200);             
        break;
        case 3:
          setCameraStatus("timer");
          setTimer(timer_5);
          setImageSrc(timer_5);          
        break;
        case 4:
          setTimer(timer_4);
          setImageSrc(timer_4);
        break;
        case 5:
          setTimer(timer_3);
          setImageSrc(timer_3);
        break;
        case 6:
          setTimer(timer_2);
          setImageSrc(timer_2);
        break;
        case 7:
          setTimer(timer_1);
          setImageSrc(timer_1);
        break;
        case 8:
          setCameraStatus("white");
          setImageSrc(undefined);
          setTimeout(() => {
            try {
              photo = webcamRef.current.getScreenshot();            
              image.src = photo;
              image.onload = () => {
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');
                drawImage(context, image, 0,-200,90,1);
                context.drawImage(pose, 0,-200);
                context.drawImage(logo, 0,0);
                const name = new Date().getTime()+".jpg";                                     
                let blob = canvas.toBlob((blob) => {   
                        setUserBlob(blob);                                                              
                        setScreenshot(canvas.toDataURL('image/jpeg'), name);
                        //changeStatus("photo");
                      }, 'image/jpeg');              
                
              }            
            } catch (e) {
              console.log(e);
            }
          }, 1200);
        break;
        case 9:
          setCameraStatus("bye");
        break;
      }
    }

    useEffect(
    () => {        
        if (status!=="camera") return;
        logo.src = initialConfig.data_url + initialConfig.watermark;
        pose.src = initialConfig.data_url + _.get(initialConfig,"players["+userPlayer+"].pose", _.get(initialConfig,"players[0].pose"));
        console.log("USERPLAYER", userPlayer, _.get(initialConfig,"players["+userPlayer+"]"));
    },[status]);

    useEffect(
      () => {
        
        let timer = setTimeout(() => {
          nextStep(); //1      
          setTimeout(() => { 
            nextStep(); //2
            setTimeout(() => { 
              nextStep();  //3     
              setTimeout(() => { 
                nextStep();  //4     
                setTimeout(() => { 
                  nextStep();   //5    
                  setTimeout(() => { 
                    nextStep();    //6   
                    setTimeout(() => { 
                      nextStep();  //7  
                      setTimeout(() => { 
                        nextStep();  //8  
                      },time); 
                    },time); 
                  },time); 
                },time); 
              },time); 
            },time);            
          },time);      
        }, initialConfig.time_to_players*1000);
        return () => {
          clearTimeout(timer);
        };

    },[]);

    return (
      <div className="camera">     
        <h1 style={{color: initialConfig.lightColor}} dangerouslySetInnerHTML={{__html: language["en"].tit_camara}} ></h1>          
        {(cameraStatus!=="ready") && <video ref={robotOverlay} className="video" src={initialConfig.data_url + _.get(initialConfig,"players["+userPlayer+"].video", _.get(initialConfig,"players[0].video"))} type="video/webm" onEnded={()=>{
          console.log("end video");
          changeStatus("photo");
        }} muted playsInline autoPlay></video>}
        {cameraStatus==="white" && <div className="whiteScreen"></div>}
        {imageSrc && <CrossfadeImage duration={800} src={imageSrc} />}
        <img className="FadeUp" src={FadeUp}/>
        { /* <img className="watermark" src={Logo}></img> */ }
        <img className={"position_"+_.get(initialConfig,"players["+userPlayer+"].position")} src={Position}></img>
        <canvas ref={canvasRef} width={1080} height={1920} className="canvas"></canvas>
        <Webcam
          className="camara"
          audio={false}
          height={1080}
          ref={webcamRef}
          videoConstraints={videoConstraints}
          screenshotQuality = {1}
          screenshotFormat="image/jpeg"
          width={1920}
        />
      </div>
      );
    

}  

export default Camera;