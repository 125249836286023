import React, {useState, useRef, useEffect} from "react";
import _, { initial } from 'lodash';
import ReactSimplyCarousel from 'react-simply-carousel';
import initialConfig from '../config/config.js';
import language from '../config/lang.js';
import anime from "animejs/lib/anime.es.js"
import './players.css';

function Players({lang, changeStatus, userPlayer}) {

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const posesAnimation = useRef();
    return (
    <div className="players" style={{backgroundColor: initialConfig.secondaryColor}}>   
        <h1 style={{color: initialConfig.mainColor}} >{language[lang].tit_jugadores}</h1>       
        <div className="carrousel" >
            <ReactSimplyCarousel
                containerProps = {{
                    className:"Rcarrousel"
                }}
                activeSlideIndex={activeSlideIndex}
                onAfterChange = {()=>{
                    if (_.get(initialConfig, "players["+activeSlideIndex+"].anim", false)){
                        setTimeout(() => {
                            posesAnimation.current.currentTime = 0;
                            document.getElementById("poses_animation").style.display = "block";
                            anime({
                                targets: document.getElementById("poses_animation").style,
                                easing: 'linear',
                                duration: 100,
                                opacity: 1,
                                changeComplete: (anim) => {
                                    console.log(anim);
                                    posesAnimation.current.play();                        
                                }
                            })                                
                        },500);
                    }
                }}
                onRequestChange={(index) => {
                    if (document.getElementById("poses_animation")) {
                        document.getElementById("poses_animation").style.display = "none";
                        document.getElementById("poses_animation").style.opacity = 0;
                    }
                    setActiveSlideIndex(index);  
                }}
                itemsToShow={3}
                itemsToScroll={1}
                forwardBtnProps={{
                    className: "arrowRightButton",
                    style:{
                        border: "7px solid" + initialConfig.mainColor,
                        backgroundColor: initialConfig.secondaryColor,
                    },                    
                    children: <svg width="110px" height="110px" viewBox="0 0 110 110">
                                <g id="Grupo">
                                <path d="M0 15.6113L37.5782 15.6113" id="Línea" fill="none" fillRule="evenodd" stroke={initialConfig.mainColor} strokeWidth="11" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M37.5779 15.5496L21.9203 0" id="Línea" fill="none" fillRule="evenodd" stroke={initialConfig.mainColor} strokeWidth="11" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M37.5779 15.5493L21.9203 31.0989" id="Línea" fill="none" fillRule="evenodd" stroke={initialConfig.mainColor} strokeWidth="11" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                              </svg>,
                    }}
                    backwardBtnProps={{
                    className: "arrowLeftButton",
                    style:{
                        border: "7px solid" + initialConfig.mainColor,
                        backgroundColor: initialConfig.secondaryColor,
                    },
                    children: <svg width="110px" height="110px" viewBox="0 0 110 110">
                                <g id="Grupo">
                                <path d="M0 15.6113L37.5782 15.6113" id="Línea" fill="none" fillRule="evenodd" stroke={initialConfig.mainColor} strokeWidth="11" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M37.5779 15.5496L21.9203 0" id="Línea" fill="none" fillRule="evenodd" stroke={initialConfig.mainColor} strokeWidth="11" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M37.5779 15.5493L21.9203 31.0989" id="Línea" fill="none" fillRule="evenodd" stroke={initialConfig.mainColor} strokeWidth="11" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                              </svg>,
                }}      
                centerMode = {true}          
                speed={400}
                easing="linear"
            >                
            {
                initialConfig.players.map( (player, index) => 
                        <video alt={initialConfig.players[index]["name" + lang.toUpperCase()]} muted id={"video_"+index} key={"video_" + index} src={initialConfig.data_url+_.get(player,"anim")} style={{ width: 464, height: 755 }} />
                )                
            }
                
            </ReactSimplyCarousel>
            
            {_.get(initialConfig, "players["+activeSlideIndex+"].anim", false) && <div id="poses_animation" className="poses_animation" style={{opacity:0, backgroundColor: initialConfig.secondaryColor}}><video style={{ width: 464, height: 755 }} ref={posesAnimation} src={initialConfig.data_url + initialConfig.players[activeSlideIndex]["anim"]} /></div> }
        </div>
        <div className="playerName" style={{color: initialConfig.mainColor}}  >{initialConfig.players[activeSlideIndex]["name" + lang.toUpperCase()]}</div>
        <div className="selected" style={{border: "10px solid "+ initialConfig.mainColor }}></div>
        <div className="fadeL" style={{background: "linear-gradient(90deg, "+ initialConfig.secondaryColor + "00 30%, "+ initialConfig.secondaryColor + "FF 60%, "+ initialConfig.secondaryColor + "FF 80%, "+ initialConfig.secondaryColor + "00 100%)"}}></div>
        <div className="fadeR" style={{background: "linear-gradient(90deg, "+ initialConfig.secondaryColor + "00 30%, "+ initialConfig.secondaryColor + "FF 60%, "+ initialConfig.secondaryColor + "FF 80%, "+ initialConfig.secondaryColor + "00 100%)"}}></div>
        <div className="continuar" style={{backgroundColor: initialConfig.mainColor}} onClick={()=>{
                console.log("juagdor seleccionado", initialConfig.players[activeSlideIndex].name);
                userPlayer(activeSlideIndex);
                changeStatus("camera")
        }}>{language[lang].select}</div>
    </div>
    );

}  

export default Players;