import React, {useState, useRef, useEffect} from "react";
import _ from 'lodash';
import initialConfig from '../config/config.js';
import language from '../config/lang.js';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './data.css';

function Data({lang, changeStatus, userInfo}) {

    const [keyboard, setKeyboard] = useState();
    const [inputText, setInputText] = useState("");
    const [userInfoData, setUserInfoData] = useState(
        {
            NOMBRE: "",
            APELLIDO1: "",
            APELLIDO2: "",
            FECHADENACIMIENTO: "",
            TELEFONO: "",
            NACIONALIDAD: "",
            EMAIL: ""
        }
    );

    const reset = () => {
        setUserInfoData({
            NOMBRE: "",
            APELLIDO1: "",
            APELLIDO2: "",
            FECHADENACIMIENTO: "",
            TELEFONO: "",
            NACIONALIDAD: "",
            EMAIL: ""
        });
    }

    const [required, setRequired] = useState(false);

    const FocusEvent = (index) => {
        const cn = "input_"+_.get(initialConfig,'fields['+index+'].name');
        const input = document.getElementsByClassName(cn)[0];
        if (!input) return;
        if (inputText) inputText.style.outline = "none";
        setInputText(input);
        keyboard.clearInput();
        keyboard.setInput(input.value);
        console.log("Focus",cn, input)
        input.style.outline = "5px solid "+ initialConfig.mainColor;
        console.log("Focus",cn, input)
    }

    const InputEvent = (input) => {
        if (!inputText) return;
        inputText.value  = input;
    }

    const ValidateEmail = (input) => {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (input.match(validRegex)) {
          return true;
        } else {
           return false;
        }
    }

    const checkRequired = () => {
        let valid = false;
        let data = {...userInfoData};
        let requires = 0;
        let checked = 0;
        initialConfig.fields.map( (field, index) => {
          const cn = "input_"+field.name;
          const input = document.getElementsByClassName(cn)[0];
          data[field.name] = input.value;
          if (field.required){
            requires++;
            if (field.type!=="email"){
                if (input.value !="") {
                    checked++;
                    console.log("isValid", field.name, input.value, valid);
                }else{                     
                    console.log("isValid", field.name, input.value, valid);
                }
            }else{
                valid = ValidateEmail(input.value);
                if (valid) checked++;
                console.log("isValidEMAIl", field.name, valid);
            }
          }else{
            console.log("isNotRequired", field.name);
          }
        })
        setUserInfoData(data);
        return requires===checked;
    }

    const checkField = (field, value) => {
        if (field.type === "email") return !ValidateEmail(value);
        else return value ==="";

    }

    function calcularEdad(fecha_nacimiento) {
        var hoy = new Date();
        var cumpleanos = new Date(fecha_nacimiento);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        return edad;
    }
    
    return (
    <div className="data" style={{backgroundColor: initialConfig.secondaryColor}}>   

        <h1 style={{color: initialConfig.mainColor}} >{language[lang].tit_data}</h1>       
        <form className="formulario">

                {
                    initialConfig.fields.map( (field, index) => 
                        _.get(initialConfig, 'fields['+index+'].show', false) ?
                        <div key={"field_"+index} className="formgroup">
                            <label style={{color: initialConfig.mainColor}}>{_.get(language,"["+lang+"]."+_.get(initialConfig,'fields['+index+'].name'))} {_.get(initialConfig,'fields['+index+'].required', false)?"*":""}</label>
                            <input 
                                onFocus={()=>FocusEvent(index)}
                                style={{color: initialConfig.mainColor, backgroundColor: (required && field.required && checkField(field, document.getElementsByClassName("input_" + field.name)[0].value)) ? "rgba(255,0,0,0.3)":"" }} 
                                type={_.get(initialConfig, 'fields['+index+'].type', "text")}
                                className={"input_"+_.get(initialConfig,'fields['+index+'].name')} 
                                name={_.get(initialConfig,'fields['+index+'].name')}                                 
                                required={_.get(initialConfig,'fields['+index+'].required', false)}>
                            </input>
                        </div> : ""
                    )
                }
            
        </form>


        <div className="continuar" style={{backgroundColor: initialConfig.mainColor}} onClick={()=>{
                setRequired(true);
                if (checkRequired()){
                    console.log("DATA", userInfoData);
                    userInfo(userInfoData);
                    reset();
                    changeStatus("legal")                    
                }
        }}>{language[lang].continue}</div>
        
        <div className="keyboardBox">
            <Keyboard
                keyboardRef={r => (setKeyboard(r))}
                onChange={(input)=>InputEvent(input)}       
            />
        </div>

    </div>
    );

}  

export default Data;