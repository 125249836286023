import React, {useState, useRef, useEffect} from "react";
import initialConfig from '../config/config.js';
import language from '../config/lang.js';
import './send.css';

function Send({lang, changeStatus}) {

    const [show, setShow] = useState(false);
    
    useEffect(
        () => {
          let timer = setTimeout(() => setShow(true), 1000);
          return () => {
            clearTimeout(timer);
          };
    },[]);

    useEffect(
        () => {
          let timer1 = setTimeout(() => changeStatus("reset"), initialConfig.timeOut_seconds * 1000);
          return () => {
            clearTimeout(timer1);
          };
    },[]);
    
    return (
    <div className="send" style={{backgroundColor: initialConfig.secondaryColor}}>   

        <h1 style={{color: initialConfig.mainColor}} >{language[lang].tit_data}</h1>       
        {show && <div className="check-container">
            <div className="check-background" style={{background: "linear-gradient(to bottom right, "+initialConfig.mainColor+"99, "+initialConfig.mainColor+"FF)"}}>
                <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>}
        <div className="continuar" style={{backgroundColor: initialConfig.mainColor}} onClick={()=>{                
                changeStatus("reset");
        }}>{language[lang].close}</div>

    </div>
    );

}  

export default Send;