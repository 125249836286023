const initialConfig = {
    api_url: "https://coctelcreativo.com/imagicc/process.php",
    backgroundColor: "#B0B6CA",
    mainColor: "#0066FF",
    secondaryColor: "#F1F3FE",
    lightColor: "#FFFFFF",
    timeOut_seconds: 30,
    time_to_players: 1,
    data_url : "/data/",
    watermark: "watermark.png",
    logo_app: "logo.png",
    logo_web: "logo_web.png",
    video: "video.mp4",
    suborigen: "Telefonica",
    orgien: "Mupi 1",
    totem: {
        name: "ATLETI CAM",
        id: "86457fa6ff59abba639826c1"
    },
    fields:[
        { name: "NOMBRE", type:"text", required: true, show: true },
        { name: "APELLIDO1", type:"text", required: true, show: true },
        { name: "APELLIDO2", type:"text", required: false, show: true },
        { name: "FECHADENACIMIENTO", type:"date", required: true, show: true },
        { name: "TELEFONO", type:"phone", required: true, show: true },
        { name: "NACIONALIDAD", type:"text", required: false, show: true },
        { name: "EMAIL", type:"email", required: true, show: true }        
    ],
    players: [
        {id: "grupo1", type:"grupal", nameES: "GRUPO MASCULINO", nameEN:"GRUPO MASCULINO", position:"center", image: "chicos.png", anim: "player_chicos.webm", video: "video_chicos.webm", pose:"pose_chicos.png"},
        {id: "player1", type:"individual", nameES: "KOKE", nameEN:"KOKE", image: "j1.png", position:"right", anim: "player_j1.webm", video: "video_j1.webm", pose:"pose_j1.png"},
        {id: "player2", type:"individual", nameES: "BARRIOS", nameEN: "BARRIOS", image: "j2.png", position:"right", anim: "player_j2.webm", video: "video_j2.webm", pose:"pose_j2.png"},
        {id: "player3", type:"individual", nameES: "OBLAK", nameEN: "OBLAK", position:"left", image: "j3.png", anim: "player_j3.webm", video: "video_j3.webm", pose:"pose_j3.png"},
        {id: "player4", type:"individual", nameES: "R. DE PAUL", nameEN: "R. DE PAUL", position:"left", image: "j4.png", anim: "player_j4.webm", video: "video_j4.webm", pose:"pose_j4.png"},
        {id: "grupo2", type:"grupal", nameES: "GRUPO FEMENINO", nameEN:"GRUPO FEMENINO", position:"center", image: "chicas.png", anim: "player_chicas.webm", video: "video_chicas.webm", pose:"pose_chicas.png"},
        {id: "player5", type:"individual", nameES: "XÈNIA PÉREZ", nameEN: "XÈNIA PÉREZ", position:"left", image: "ja1.png", anim: "player_ja1.webm", video: "video_ja1.webm", pose:"pose_ja1.png"},
        {id: "player6", type:"individual", nameES: "ESTEFANÍA BANINI", nameEN: "ESTEFANÍA BANINI", position:"right", image: "ja2.png", anim: "player_ja2.webm", video: "video_ja2.webm", pose:"pose_ja2.png"},
        {id: "player7", type:"individual", nameES: "AINHOA MORAZA", nameEN: "AINHOA MORAZA", position:"right",  image: "ja3.png", anim: "player_ja3.webm", video: "video_ja3.webm", pose:"pose_ja3.png"},
        {id: "player8", type:"individual", nameES: "CARMEN MENAYO", nameEN: "CARMEN MENAYO", position:"right", image: "ja4.png", anim: "player_ja4.webm", video: "video_ja4.webm", pose:"pose_ja4.png"},
    ]
}

export default initialConfig;

