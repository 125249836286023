import React, {useState, useCallback, useEffect} from "react";
import './App.css';
import Welcome from './sections/welcome';
import Send from './sections/send.js';
import Players from './sections/players.js';
import Data from './sections/data.js';
import Legal from './sections/legal.js';
import Photo from './sections/photo';
import Camera from './sections/camera';
import initialConfig from './config/config.js';
import Header from "./sections/components/Header.js";
import Footer from "./sections/components/Footer.js";
import Transition from "./sections/components/transition";
import axios from 'axios';

function App() {

  const [status, setStatus] = useState("welcome")
  const [screenshot, setScreenshot] = useState(undefined)
  const [name, setName] = useState("")
  const [lang, setLang] = useState("es")
  const [userInfo, setUserInfo] = useState({});
  const [userLegal, setUserLegal] = useState({});
  const [userPlayer, setUserPlayer] = useState(undefined);
  const [userBlob, setUserBlob] = useState(undefined);
  const [transitionState, setTransitionState] = useState("paused");

  const sendRequest = () => {
    try {
      console.log("Sending request", userInfo, userLegal, userPlayer, userBlob);
      if (!userBlob) {
        console.log("ERROR DE ENVIO");
        return;
      }
      const file = new File([userBlob], name, { type: 'image/jpeg' });
      const formData = new FormData();
      formData.append("lang",lang);
      formData.append("nombre",userInfo.nombre);
      formData.append("apellido1",userInfo.apellido1);
      formData.append("apellido2",userInfo.apellido2);
      formData.append("fechadenacimiento",userInfo.fechadenacimiento);
      formData.append("telefono",userInfo.telefono);
      formData.append("nacionalidad",userInfo.nacionalidad);
      formData.append("email",userInfo.email);
      formData.append("legal",userLegal);
      formData.append("origen",initialConfig.orgien);
      formData.append("suborigen",initialConfig.suborigen);
      formData.append(
        "files",
        file                
      );
      axios.post(initialConfig.api_url, formData);   
    } catch (e) {
      console.log(e);
    }
  }

  const handleEvent = (event) => {
    console.log(status);
    if (status !== "data") {
      switch(event){
        case 87:
          nextStatus("welcome");
          break;
        case 68:
          nextStatus("data");
          break;
        case 76:
          nextStatus("legal");
          break;
        case 74:
          nextStatus("players");
          break;
        case 67:
          nextStatus("camera");
          break;
        case 80:
          nextStatus("photo");
          break;
        case 83:
          nextStatus("send");
          break;
      }
    }
  };

  const handleUserKeyPress = useCallback(event => {
    const { key, keyCode } = event;
    //console.log(key, keyCode);       
    handleEvent(keyCode);
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
        window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const nextStatus = (new_status) => {
    console.log(new_status);
    setTransitionState("close");
    setTimeout(()=>{
      if (new_status==="reset"){
          setStatus("welcome");
          setName("");    
          setScreenshot(undefined);
          setLang("es");
          setUserInfo({});    
          setUserLegal({});    
          setUserPlayer(undefined);    
          setUserBlob(undefined);    
      } else if (new_status==="repeat"){ 
          setName("");    
          setScreenshot(undefined);
          setStatus("camera");
          setUserBlob(undefined);    
      }  else if (new_status==="send"){  
          sendRequest();
          setStatus(new_status);
      } else setStatus(new_status);
      setTimeout(()=>{
        setTransitionState("open");
      },500);   
    },1000);
  }
 
  return (
    <div className="App" style={{backgroundColor: initialConfig.backgroundColor}}>
      <Header/>
      <Transition color={initialConfig.mainColor} state={transitionState}/>           
      {status==="welcome" && <Welcome changeLanguage={(value)=>setLang(value)} changeStatus={(new_status)=>nextStatus(new_status)}/>}
      {status==="data" && <Data userInfo={(value)=>setUserInfo(value)} lang={lang} changeStatus={(new_status)=>nextStatus(new_status)}/>}
      {status==="legal" && <Legal userLegal={(value)=>setUserLegal(value)} lang={lang} changeStatus={(new_status)=>nextStatus(new_status)}/>}
      {status==="players" && <Players userPlayer={(value)=>setUserPlayer(value)} lang={lang} changeStatus={(new_status)=>nextStatus(new_status)}/>}
      {status==="camera" && <Camera lang={lang} userPlayer={userPlayer} status={status} setUserBlob={(blob)=>setUserBlob(blob)} setScreenshot={(photo, name)=>{setScreenshot(photo); setName(name)}} changeStatus={(new_status)=>nextStatus(new_status)}/>}
      {status==="photo" && <Photo lang={lang} name={name} changeStatus={(new_status)=>nextStatus(new_status)} screenshot={screenshot} />}
      {status==="send" && <Send lang={lang} changeStatus={(new_status)=>nextStatus(new_status)}/>}
      {status!=="welcome" && <Footer/>}
    </div>
  );

}

export default App;
